import { useEffect } from 'react';

const FcMagdeburg = () => {
  useEffect(() => {
    localStorage.setItem('club_id', 513);
    localStorage.setItem('club_name', '1. FC Magdeburg');

    window.location.assign('/');
  });

  return null;
};
export default FcMagdeburg;
